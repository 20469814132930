
<div class="table-button-container home" (click)="checkTokenFromStorage()" style="padding-top:6px"> 
      
  <button mat-fab color="warn" class="home-button" aria-label="Home" (click)="navigateToHome()">
    <mat-icon>home</mat-icon>
  </button>

  <span class="files_text">{{ 'transcription.title' | translate }}</span>
  
 
</div>


<div class="table-button-container" style="height:83%" (click)="checkTokenFromStorage()" >
  
  <div class="header-container" style="padding:1%;font-size: larger; height: 7%;">
    <div class="left-header-column" style="padding-left:20px; display: flex; align-items: center;">
      <span><b>{{'home.fileName' | translate}}:</b> {{fileName}}</span>
    </div>
    <div class="right-header-column" style="display: contents; justify-content: right; padding-right:20px;display: flex; align-items: center;">
      <span style="padding-right:20px;"><b>{{'home.asrDomain' | translate}}:</b> {{asrDomain}}</span>
    </div>
  </div>
  <div id = "scroll-view" class= "transcript-scroolable" >
    <div id="inner-scroll" style="margin-top:10px">
    <div *ngFor="let segment of transcription; index as index_segment">
      <div class="segment-colorbar-container">
        <div class="left-column-segment">
          <div class = "segment-container" >
            <div class = "speaker-title-container"> 
              <img class = "custom-avatar" [ngStyle]="{'background-color':map[segment.speakerName]}" *ngIf="segment.speakerGender=='M'" [src]="'assets/images/male.svg'" style="height: 50px; border-radius: 90px; margin:10px;" (click)="changeGender(index_segment,'F')">
              <img class = "custom-avatar" [ngStyle]="{'background-color':map[segment.speakerName]}" *ngIf="segment.speakerGender=='F'" [src]="'assets/images/female.svg'" style="height: 50px; border-radius: 90px;margin:10px;" (click)="changeGender(index_segment,'M')">
              <img class = "custom-avatar" [ngStyle]="{'background-color':map[segment.speakerName]}" *ngIf="segment.speakerGender=='U'" [src]="'assets/images/unknown.png'" style="height: 50px; border-radius: 90px;margin:10px;">
              

<!--                 <mat-form-field appearance="outline" hideRequiredMarker> 
                
                
                <input matInput 
                type="text"
                [(ngModel)]="speakers[segment.speakerName]"
                #speakerName="ngModel" 
                name="speakers.get[segment.speakerName]"
                placeholder="{{ 'transcription.speaker' | translate }}: {{trimNgModelSpeakerName(speakers.get(segment.speakerName))}}"
                />
                </mat-form-field> -->
              <span #txtInputSpeaker contenteditable="true" class = "speaker-name-container" style="width: max-content;" (focusout)="editingSpeakerFinished($event,index_segment)" (input)="updateSpeakerChanges($event,index_segment)" (keydown.escape)="txtInputSpeaker.blur()" (keydown.control.s)="$event.preventDefault();txtInputSpeaker.blur()"> {{segment.speakerName}} </span> 

            </div>
            <div id={{index_segment}} #txtInput class = "speaker-word-container"  (mousedown)="onMouseDown($event)" ondblclick="this.contentEditable='plaintext-only'; this.focus(); " (dblclick)="saveOriginalTextEdit(index_segment)" onblur="this.contentEditable=false;" (focusout)="editingFinished(index_segment)" (keydown.escape)="txtInput.blur()" (keydown.control.s)="$event.preventDefault();txtInput.blur()"> 
              
              <span *ngFor="let word of segment.wordsArray; index as index_word" >
              <span [ngStyle]="{'background-color': (word.start < audio.currentTime) && (audio.currentTime < (word.start + word.length))? 'yellow' : 'rgb(222 228 231)','color': (word.confidence < 0.7 && word.confidence > 0)? '#F80000' : (word.confidence < 0.9 && word.confidence >= 0.7)? '#BA0000' : 'black'}" 
              (dblclick) = "wordDbClick(index_segment,index_word)" (click)="wordClick(word, word.start, index_word, segment.segmentStart, segment.segmentLength , word.length, 'this.contentEditable')" class="word-style" [innerHTML]="word.word | safeHtml ">
              </span>
              <span id="word-indentifier" *ngIf="((word.start < audio.currentTime) && (audio.currentTime < (word.start + word.length)))"> 

              </span>
            </span>
            </div>

            
          </div>
<!--           <div class = "segment-container" *ngIf="!segment.leftAlignment">
            <div #txtInput class = "speaker-word-container" (mousedown)="onMouseDown($event)" ondblclick="this.contentEditable='plaintext-only'; this.focus();" onblur="this.contentEditable=false;" (focusout)="editingFinished(index_segment)" (input)="updateTextChanges($event,index_segment)" (keydown)="disableCtrlKeys($event)" (keydown.escape)="txtInput.blur()"> 

              <span *ngFor="let word of segment.wordsArray; index as index_word" 
              [ngStyle]="{'background-color': (word.start < audio.currentTime) && (audio.currentTime < (word.start + word.length))? 'yellow' : 'rgb(222 228 231)' }" 
              (dblclick) = "wordDbClick(index_segment,index_word)" (click)="wordClick(word, word.start, word.length, 'this.contentEditable')" class="word-style"  [innerHTML]="word.word | safeHtml">
              <span id="word-indentifier" *ngIf="((word.start < audio.currentTime) && (audio.currentTime < (word.start + word.length)))" ></span></span>
            </div>
            <div class = "speaker-title-container"> 
              <img class = "custom-avatar" *ngIf="segment.speakerGender=='M'" [ngStyle]="{'background-color':map[segment.speakerName]}" [src]="'assets/images/male.svg'" style="width: 90px; border-radius: 90px;">
              <img class = "custom-avatar" *ngIf="segment.speakerGender=='F'" [ngStyle]="{'background-color':map[segment.speakerName]}" [src]="'assets/images/female.svg'" style="width: 90px; border-radius: 90px;">
              <img class = "custom-avatar" [ngStyle]="{'background-color':map[segment.speakerName]}" *ngIf="segment.speakerGender=='U'" [src]="'assets/images/unknown.png'" style="width: 90px; border-radius: 90px;">
              <mat-form-field appearance="outline" hideRequiredMarker> 
                
                <input matInput
                type="text"
                [(ngModel)]="speakers[segment.speakerName]"
                [ngModelOptions]="{standalone:true}"
                #speakerName="ngModel" 
                name="speakers.get[segment.speakerName]"
                placeholder="{{ 'transcription.speaker' | translate }}: {{trimNgModelSpeakerName(speakers.get(segment.speakerName))}}"
                />
                </mat-form-field>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    </div>
  </div>

  <div class="wrapper" style="height: 10%;">
    <div class="header-container" style="padding-left: 10px;align-items: center; ">
      <div class="left-header-column audio-container" style="padding-left:20px;" style="padding: 1%;">
        <video oncontextmenu="return false;" style="position:fixed; bottom:5%; right:3%; z-index: 9999;max-width:25%" cdkDrag id="myaudio" [src]="safeblobUrl" (timeupdate) = "updateCurrentTime()" [ngStyle]="{'display': (contentType=='video/mp4')? 'block' : 'none'}"></video>

        <button mat-fab color="warn" (click)="openModal('custom-modal-2')" class="button-download">
          <mat-icon>file_download</mat-icon>
        </button>

        <button *ngIf="!playing" mat-fab color="warn" (click)="playAudio()" class="button-play">
          <mat-icon>play_arrow</mat-icon>
        </button>

        <button *ngIf="playing" mat-fab color="warn" (click)="pauseAudio()" class="button-pause">
          <mat-icon>pause</mat-icon>
        </button>
        
        
        <div class="ff-div" >

            <fa-icon  class="icon-ff" [icon]="faForward"></fa-icon>
            <mat-select disableOptionCentering [(value)]="playbackSpeed" (valueChange)="changeSpeed()" style="width:50%;">
              <mat-option value="0.25">0.25x</mat-option>
              <mat-option value="0.50">0.50x</mat-option>
              <mat-option value="0.75">0.75x</mat-option>
              <mat-option value="1">1.00x</mat-option>
              <mat-option value="1.25">1.25x</mat-option>
              <mat-option value="1.5">1.50x</mat-option>
              <mat-option value="1.75">1.75x</mat-option>
              <mat-option value="2">2.00x</mat-option>
            </mat-select>

        </div>
        <div style="display: inline-block; position: relative;margin-left: 10px;">
        {{currentTime | parseTimeFormatPlayer}}/{{length | parseTimeFormatPlayer}}
        </div>
      </div>
    </div>
    <div id="progress-bar" class="player-progress-bar-container">
      <mat-slider class="progress-bar-slider" [ngStyle]="{'background':speakerSegmentsDivision}" aria-label="unit(s)" [(ngModel)]="slidervalue" 
        (input) = "sliderMove()" (change)="sliderRelease()"></mat-slider>
        <mat-checkbox class="checkbox-custom" matTooltip="Stop playing the audio at segment end" [(ngModel)]="stopAudioAtSegmentEnd"></mat-checkbox>
    </div>
    <div>
      
    </div>
  </div>
</div>



<request-modal id="custom-modal-2" class="custom-modal">
  <div style="display: grid;  justify-content: right;"><fa-icon style="transform: scale(2); color: #0f8b99;  top: -32px;  right: -32px;position: relative;" [icon]="faCircleX" (click)="closeModal('custom-modal-2');"></fa-icon></div>
  <h1>{{'home.downloadTranscription' | translate }}</h1>
  <h2>{{fileName}}</h2>
  <mat-form-field appearance="outline" class="modal-form" style="width: 100%;">
    <mat-label>{{'home.selectFileFormat' | translate }}</mat-label>
    
    <mat-select [(ngModel)]="selectedDownloadFormat">
      <mat-option *ngFor="let element of downloadFormatList" [value]="element">
        {{element}}
      </mat-option>
    </mat-select>
  </mat-form-field>
 <div style="display: grid;  justify-content: center;">
  <button mat-raised-button color="success" (click)="downloadTranscriptionFile('custom-modal-2', jobId, fileName , selectedDownloadFormat)" class="btn btn-primary btn-block text-login" >
    <fa-icon *ngIf="selectedDownloadFormat=='docx'" class = "icon-actions" [icon]="faDocumentWord"></fa-icon>
    <fa-icon *ngIf="selectedDownloadFormat=='json'" class = "icon-actions" [icon]="faDocumentCode"></fa-icon>
    <fa-icon *ngIf="selectedDownloadFormat=='srt'" class = "icon-actions" [icon]="faDocumentSubtitle"></fa-icon>
    <span style="padding-left: 10px;">{{ 'home.download' | translate }}</span>
  </button>
  </div>
</request-modal>